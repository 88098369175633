<div class="private-content" fxFlexFill fxLayout="column">
  <div class="topbar"></div>
  <mat-sidenav-container>
   
    <button class="u4-sidebar-button" mat-icon-button (click)="isNavToggled = !isNavToggled">
            <mat-icon matTooltip="Open / sluit menu" class="u4-sidebar-button-icon">{{ GetMenuToggleIcon()}}</mat-icon>
    </button>
    <mat-sidenav mode="side" #sidenav [opened]="false" class="u4-sidebar" [ngClass]="{'mat-sidenav-opened': isNavToggled ,'mat-sidenav-closed': !isNavToggled  }">

      <div class="u4-sidebar-header">
        <ng-template [ngIf]="isNavToggled">
          <img class="sidebar-logo-big"  src="../assets/images/cropped-DIAS_logo_website-1-45x45.png" />
          
        </ng-template>
        <ng-template [ngIf]="!isNavToggled ">
          <img  class="sidebar-logo-small" src="../assets/images/cropped-DIAS_logo_website-1-45x45.png" />

        </ng-template>
      </div>

      <div class="u4-sidebar-main" fxflex="auto" style="overflow: hidden;">
        <mat-list class="ui-c-sidebar__nav">
            <mat-list-item *ngFor="let route of routerMain" class="ui-c-sidebar__nav-item">
                <a [routerLink]="route.routerLink"     class="ui-c-sidebar__nav-item-link" md-offset="0 72" routerLinkActive="md-button-focus">
                    <mat-icon [ngClass]="route.icon" class="fa ui-o-icon ui-c-sidebar__nav-item-link-icon"></mat-icon>
                    <div [ngClass]="!isNavToggled ? 'hideme':'showme'">{{route.name}}</div>
                </a>
            </mat-list-item>
        </mat-list>
      </div>
      <div class="u4-sidebar-footer" fxflex="auto"></div>
    </mat-sidenav>

    <app-header></app-header>
    <div class="router-content">
      <router-outlet></router-outlet>
    </div>
  </mat-sidenav-container>


</div>