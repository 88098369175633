import { Routes, RouterModule } from '@angular/router';
import { SelfSignupComponent } from './self-signup/self-signup.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { WelcomeComponent } from './welcome.component';
import { InvitedComponent } from './invited/invited.component';

const welcomeRoutes: Routes = [
 {path: '', redirectTo: '/start', pathMatch: 'full'},
    {
        path: 'welcome', component: WelcomeComponent,
        children: [
            { path: 'introduction', component: IntroductionComponent },
            { path: 'invited', component: InvitedComponent },
            { path: 'selfsignup', component: SelfSignupComponent }
        ]
    }
];

export const WelcomeRoutes = RouterModule.forChild(welcomeRoutes);
