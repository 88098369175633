import { Component, OnInit } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthorisationService } from '../../shared/oauth/authorisation.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { LocalStorage } from '../../modules/browser-storage';
import { Subscription } from 'rxjs';
import { LoaderComponent } from '../../shared/loader/loader.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.scss']
})

export class StartComponent implements OnInit {
  private subscription: Subscription;
  public isDataLoaded = false;
  public isOwner = false;
  public isUploading = false;
  public isUploaded = false;
  public currentState = 0;
  public initialdbcheck = null;
  public initialstatus = { 'value': { "state": "0", "stateDescription": "U heeft nog geen omgeving", "stateChangedOn": "9/18/2017 12:06:28 PM" } };

  constructor(private loader: LoaderComponent, private as: AuthorisationService,
    private communicationService: CommunicationService, private localStorage: LocalStorage, public dialog: MatDialog) {
  }

  ngOnInit() {
    // Check if the user is an owner by claims
    this.as.isOrganizationAdmin().then(result => {
      this.isOwner = result
    });

    // Check for status in localstorage to show correct html
    if (this.localStorage.getObject('status') != null) {
      this.initialstatus = this.localStorage.getObject('status');
    }

    this.communicationService.environmentStatus().subscribe(result => {
      this.initialstatus = result;
    });

    this.communicationService.isDatabaseUploaded().then(result => {
      this.isDataLoaded = true;
      this.isUploaded = result;
      if (result)
        this.initialdbcheck = false;
    });
  }

  public checkEnvironmentStatus() {
    this.communicationService.environmentStatus().subscribe(result => {
      this.initialstatus = result;
      this.loader.done();
    });
  }

  public checkEnvironmentStatusLoop() {

    this.subscription = this.communicationService.environmentStatusLoop().subscribe(result => {
      this.initialstatus = result;
      this.localStorage.setObject('status', this.initialstatus);
      if (this.initialstatus.value.state >= '4') {
        this.subscription.unsubscribe();
      }

      console.log('Status is:' + result);

    }, fault => {
      console.log(fault);
      this.subscription.unsubscribe();
    });
  }

  public get environmentStatus() {
    return (Number(this.initialstatus.value.state));
  }

  public get name() {
    return this.as.name != null ? this.as.name : '';
  }

  public handleUploadStarted(started): void {
    this.isUploading = started;
  }

  public handleUploaded(uploadStatus): void {
    this.isUploaded = uploadStatus;
    this.isUploading = false;
  }

  public startProcess(): void {
    this.isUploaded = false;
    this.loader.show();
    this.initialstatus = { 'value': { 'state': '1', 'stateDescription': 'U heeft nog geen omgeving', 'stateChangedOn': '9/18/2017 12:06:28 PM' } };
    this.createOrUpdateEnvironment();
  }

  private onResetDatabase(): void {

    let dialogRef = this.dialog.open(ResetDatabaseDialog, {
      width: '500px'
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.resetDatabase();
      }
    });
  }
  
  private resetDatabase() {
    this.loader.show();
    this.communicationService.resetEnvironment().subscribe(result => {
      this.checkEnvironmentStatus();
      this.isUploading = false;
      this.isUploaded = false;
      this.initialdbcheck = null;
      console.log('Deleted database');
    }, fault => {
      console.log(fault);
    });
  }

  private createOrUpdateEnvironment(): void {
    this.communicationService.createOrUpdateEnvironment().subscribe(
      result => {
        this.checkEnvironmentStatusLoop();
        console.log('Created or updated environment!');
        this.loader.done();
      }, fault => {
        this.loader.done();
        console.log(fault);
      });
  }
}
@Component({
  selector: 'app-reset-database.dialog',
  templateUrl: 'reset-database.dialog.html',
})
export class ResetDatabaseDialog { }