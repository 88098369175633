<div class="container-welcome">
  <div class="left"></div>
  <div class="middle">
    <div class="welcome-title">
      <h1>
      Welkom bij DIAS Advies
      </h1>
    </div>
    
    <div class="welcome-tekst">
      Alle tools voor een compleet en transparant financieel advies.<br/>
      Probeer DIAS Advies nu 30 dagen gratis en vrijblijvend.
    </div>
    
    <div class="flex-container">
      <div class="flex-item">
        <div class="container">
          <div class="text">
            Heeft u een Office 365 of Microsoft account, ga direct verder met het aanvragen van een proeflicentie
          </div>
          <button mat-button color="primary" (click)="login()">
            Aanvragen
          </button>
        </div>
      </div>
    
      <div class="flex-item">
        <div class="container">
          <div class="text">
            Heeft u geen Office 365 account? Maak deze dan eerst gratis aan.
          </div>
          <button mat-button color="primary" (click)="gotoCreateLiveAccount()">
              Office account maken
          </button>
        </div>
        <div class="container">
          <div class="text">
            <div>
              Wilt u liever uw huidige e-mailadres gebruiken? Maak dan gratis een Microsoft Azure AD aan.
            <div class="tooltip">
              <mat-icon >info_outline</mat-icon>
              <span class="tooltiptext">Voor het maken van een Azure AD heeft u een credit card en wellicht de hulp van uw systeembeheerder nodig.</span>            
            </div>   
            </div>       
          </div>
          <button mat-button color="primary" (click)="gotoCreateAzureAccount()">
            Azure AD maken
          </button>
        </div>
      </div>
    
      <div class="flex-item">
        <div class="container">
          <div class="text">
            Wilt u liever samen met een medewerker van DIAS Software een proeflicentie aanvragen?
          </div>
          <button mat-button color="primary" (click)="gotoContact()">
              Contact aanvragen
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="right">

  </div>
