import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorisationService } from '../../shared/oauth/authorisation.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../../environments/environment';
import { Organization } from '../../classes/organization.class';

@Component({
  selector: 'app-introduction',
  templateUrl: './introduction.component.html',
  styleUrls: ['./introduction.component.scss']
})
export class IntroductionComponent implements OnInit {

  constructor(private router: Router,
    private AuthorisationService: AuthorisationService,
    private communicationService: CommunicationService,
    private authService: MsalService,
    private organization: Organization) { }

  ngOnInit() {
  }

  gotoContact() {
    this.router.navigate(['/welkom/proeflicentie/advies/contact']);
  }

  login() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1 || window.navigator.userAgent.indexOf('Edge') > -1;
    const loginRequest = {
      scopes: [
        "user.read",
        "openid",
        "profile",
        "email"
      ],
      additionalScopesToConsent: ['user.read'],
      prompt: 'select_account'
    };

    if (isIE) {
      this.authService.loginRedirect(loginRequest);
    } else {
      this.authService.loginPopup(loginRequest).then(
        (loginResponse) => {
          this.organization.reset();
          window.location.replace(environment.redirectUri);
        }).catch(function (error) {
          console.log(error);
        });
    }
  }

  gotoCreateLiveAccount() {
    var win = window.open('https://signup.live.com/signup', '_blank');
    win.focus();    
  }

  gotoCreateAzureAccount() {
    var win = window.open('https://signup.azure.com/signup', '_blank');
    win.focus();    
  }

}
