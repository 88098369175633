import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { CommunicationService } from '../../shared/services/communication.service';

@Component({
  selector: 'app-dbcheck',
  templateUrl: './dbcheck.component.html',
  styleUrls: ['./dbcheck.component.scss']
})
export class DbcheckComponent implements OnInit {
  @Output() dbcheckChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input()
  public dbcheck: boolean;
  @Input()
  public isDisabled: boolean;
  @Input()
  public defaultcheck: boolean = null;
  public noDatabase: boolean;
  public yesDatabase: boolean;
  public isDataLoaded: boolean;

  constructor(private communicationService: CommunicationService) {

  }

  async ngOnInit() {
    await this.communicationService.isDatabaseUploaded().then(result => {
      this.isDataLoaded = true;
      if (result) {
        this.isDisabled = true;
        this.yesDatabase = true;
        this.chkChange(true, null)
      }
    });
  }

  public chkChange(b, e) {
    // if b = true user has database "yes"
    if (b && this.yesDatabase) {
      this.noDatabase = false;
      this.dbcheck = true;
    } else if (!b && this.noDatabase) {
      this.yesDatabase = false;
      this.dbcheck = false;
    } else {
      this.dbcheck = null;
    }
    this.dbcheckChange.emit(this.dbcheck);
  }
}
