import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { OauthGuard } from './guards/oauth.guard';
import { LocationStrategy, HashLocationStrategy} from '@angular/common';
import { WelcomeComponent } from './welcome/welcome.component';
import { SelfSignupComponent } from './welcome/self-signup/self-signup.component';
import { StartComponent } from './private/start/start.component';
import { MainComponent } from './private/main.component';
import { PendingChangesGuard } from './guards/PendingChanges.guard';
import { SelfSignUpGuard } from './guards/selfsignup.guard.';
import { InvitedComponent } from './welcome/invited/invited.component';
import { SignedupComponent } from './welcome/signedup/signedup.component';
import { IntroductionComponent } from './welcome/introduction/introduction.component';

const appRoutes: Routes = [
    { path: 'app', component: MainComponent,
        children: [
            { path: 'start', canActivate: [OauthGuard], component: StartComponent },
            { path: '', redirectTo: '/start', pathMatch: 'full' }
        ]
    },
    { path: 'welkom', component: WelcomeComponent,
        children: [
            { path: 'invited', component: InvitedComponent },
            { path: 'signedup', component: SignedupComponent },
            { path: 'proeflicentie/advies', canActivate: [SelfSignUpGuard], component: IntroductionComponent },
            { path: 'proeflicentie/advies/contact', canActivate: [SelfSignUpGuard], component: SelfSignupComponent },
            { path: '', redirectTo: '/welkom/proeflicentie/advies', pathMatch: 'full' }
        ]
    },
    { path: 'proeflicentie/advies', canActivate: [SelfSignUpGuard], component: SelfSignupComponent },
    { path: 'welkom', component: WelcomeComponent },
    { path: 'invited', redirectTo: 'welkom/invited' },
    { path: 'signedup', redirectTo: 'welkom/signedup' }
];

export const authProviders = [
    OauthGuard
];
@NgModule({

    imports: [RouterModule.forRoot(appRoutes)],
    exports: [RouterModule],
    providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
})
export class AppRoutingModule { }
