import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// import { MaterialModule } from '@angular/material';
import { SelfSignupComponent } from './self-signup/self-signup.component';
import { IntroductionComponent } from './introduction/introduction.component';
import { WelcomeComponent } from './welcome.component';
// import { CommunicationService } from '../services/communication.service';
import { FlexLayoutModule } from '@angular/flex-layout';
import { WelcomeRoutes } from './welcome.routing';
import { SharedModule } from '../shared/shared.module';
@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    FormsModule,
    WelcomeRoutes,
    FlexLayoutModule,
    ReactiveFormsModule,
    // MaterialModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    SharedModule,
  ],
  declarations: [
    SelfSignupComponent,
    IntroductionComponent,
    WelcomeComponent,

  ],
  providers: [
    // CommunicationService
  ],
  exports:[ WelcomeComponent ]
})
export class WelcomeModule { }
