import { Component, Injectable, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TimerObservable } from 'rxjs/observable/TimerObservable';
@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})

@Injectable()
export class LoaderComponent implements OnInit {
    static loaders= 0;
    static globalLoader: LoaderComponent = null;
    hidden = true;

    @Input() loader: LoaderComponent;
    ngOnInit() {
        if (this.loader) {
            LoaderComponent.globalLoader = this.loader;
        }
    }

    done() {
        LoaderComponent.loaders--;
        if (LoaderComponent.loaders <= 0) {
            LoaderComponent.globalLoader.hidden = true;
            LoaderComponent.loaders = 0;
        }
    }

    show() {
        LoaderComponent.loaders++;
        let timer = TimerObservable.create(200);
        timer.subscribe(t => {
            if (LoaderComponent.loaders > 0) {
                LoaderComponent.globalLoader.hidden = false;
            }
        });
    }
}