import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main.component';
import { StartComponent } from './start/start.component';
import { OauthGuard } from '../guards/oauth.guard';
import { TrialEnvironmentComponent } from './trial-environment/trial-environment.component';
import { StartGuard } from './start/start.guard';
import { TrialEnvironmentGuard } from './trial-environment/trial-environment.guard';

const mainRoutes: Routes = [
    {
        path: '', canActivate: [OauthGuard], component: MainComponent,
        children: [
            { path: 'start', component: StartComponent, canActivate: [StartGuard] },
            { path: 'advies/trialenvironment', component: TrialEnvironmentComponent, canActivate: [TrialEnvironmentGuard] }
        ]
    }
];
export const MainRoutes = RouterModule.forChild(mainRoutes);