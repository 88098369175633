import { Component, Output, Input, OnInit, EventEmitter, ViewContainerRef, HostListener } from '@angular/core';
import { UploadOutput, UploadInput, UploadFile, humanizeBytes, UploaderOptions } from 'ngx-uploader';
import { AuthorisationService } from '../../shared/oauth/authorisation.service';
import { CommunicationService } from '../../shared/services/communication.service';
import { environment } from '../../../environments/environment';
import { ToastrService } from 'ngx-toastr';
import { ComponentCanDeactivate } from '../../guards/PendingChanges.guard';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { promise } from "protractor";
import "rxjs/add/operator/map"
import { MsalService } from '@azure/msal-angular';
import { AuthResponse, ServerHashParamKeys } from 'msal';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss']
})
export class UploadComponent implements ComponentCanDeactivate, OnInit {
  options: UploaderOptions;
  formData: FormData;
  files: UploadFile[];
  uploadInput: EventEmitter<UploadInput>;
  humanizeBytes: Function;
  dragOver: boolean;
  canLeave = true;
  sizeLimit = 2147483647; // 2 gb is the limit for a streaming upload by hosting in Azure Website (IIS restriction)
  @Output() uploadChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() uploadStarted: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Input() public uploadSucces: boolean = false;
  public isDataLoaded: boolean = false;
  public isUploading: boolean = false;
  public color = 'primary';
  public mode = 'determinate';
  public value = 0;
  private baseUrl = environment.apiUrl;
  private headers: { Authorization: string; OrganizationId: string  };

  constructor(private authorisationService: AuthorisationService, private communicationService: CommunicationService, public toastr: ToastrService, private httpClient: HttpClient, containerRef: ViewContainerRef, private auth: MsalService) {
    this.files = []; // local uploading files array
    this.uploadInput = new EventEmitter<UploadInput>(); // input events, we use this to emit data to ngx-uploader
    this.humanizeBytes = humanizeBytes;
    
    // this.options.concurrency = 1;
    //this.options = { allowedContentTypes: ['.bacpac'] } as UploaderOptions;
  }
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    // insert logic to check if there are pending changes here;
    // returning true will navigate without confirmation
    // returning false will show a confirm dialog before navigating away
    return this.canLeave;
  }

  showSuccess() {
    this.toastr.success('Database upload compleet!', 'Upload process');
    this.uploadChange.emit(this.uploadSucces);
    this.canLeave = true;
  }

  showError(title: string, body: string) {
    this.toastr.error(body, title);
    this.uploadChange.emit(this.uploadSucces);
    this.canLeave = true;
  }

  async ngOnInit() {
    await this.communicationService.isDatabaseUploaded().then(result => {
      this.isDataLoaded = true
      this.uploadSucces = result;
    });

    const scopes = this.auth.getScopesForEndpoint(environment.apiUrl);
    this.auth.getLogger().verbose("Url: " + environment.apiUrl + " maps to scopes: " + scopes);

    var currentOrganizationId: string = localStorage.getItem('currentOrganizationId');

    let token: string;
    this.auth.acquireTokenSilent({ scopes })
        .then((response: AuthResponse) => {
            token = response.tokenType === ServerHashParamKeys.ID_TOKEN ? response.idToken.rawIdToken : response.accessToken;
            this.headers = { Authorization: `Bearer ${token}`, OrganizationId: currentOrganizationId };               
        })
  }

  onUploadOutput(output: UploadOutput): void {
    if (output.type === 'allAddedToQueue') { // when all files added in queue
      // uncomment this if you want to auto upload files when added
      // const event: UploadInput = {
      //   type: 'uploadAll',
      //   url: '/upload',
      //   method: 'POST',
      //   data: { foo: 'bar' }
      // };
      // this.uploadInput.emit(event);
    } else if (output.type === 'addedToQueue' && typeof output.file !== 'undefined') { // add file to array when added     
      let fileExtension;
      if (output.file.name.lastIndexOf('.') > 0) {
        fileExtension = output.file.name.substring(output.file.name.lastIndexOf('.') + 1, output.file.name.length);
      }

      if (output.file.size > this.sizeLimit) {
        this.showError('Upload melding', 'Uw database is groter dan 2GB en kan daardoor niet worden geupload');
        this.removeAllFiles();
      } else if (fileExtension.toLowerCase() == 'bacpac') {
        this.files.push(output.file);
      } else {
        this.showError('Upload melding', 'Huidige bestandstype wordt niet ondersteund');
        this.removeAllFiles();
      }      
    } else if (output.type === 'uploading' && typeof output.file !== 'undefined') {
      this.isUploading = true;
      this.uploadStarted.emit(true);
      this.canLeave = false;
      // update current data in files array for uploading file
      const index = this.files.findIndex(file => typeof output.file !== 'undefined' && file.id === output.file.id);
      this.files[index] = output.file;
    } else if (output.type === 'removed') {
      // remove file from array when removed
      this.uploadStarted.emit(false);
      this.files = this.files.filter((file: UploadFile) => file !== output.file);
    } else if (output.type === 'dragOver') {
      this.dragOver = true;
    } else if (output.type === 'dragOut') {
      this.dragOver = false;
    } else if (output.type === 'drop') {
      this.dragOver = false;
    } else if (output.type === 'done') {
      if (output.file.responseStatus === 200) {
        this.isUploading = false;
        this.uploadSucces = true;
        this.showSuccess();
        this.removeAllFiles();
      } else if (output.file.responseStatus === 0) {
        this.showError('Server offline', 'Probeer het later opnieuw?');

      } else {
        this.showError('Server foutmelding', "Probeer het later opnieuw");
      }
    }
  }


  startUpload(): void {
    const event: UploadInput = {
      type: 'uploadFile',
      url: environment.apiUrl + '/uploaddatabasebacpac',
      method: 'POST',
      file: this.files[0],
      withCredentials: true,
      headers: this.headers
    };

    this.uploadInput.emit(event);
  }

  cancelUpload(id: string): void {
    this.uploadInput.emit({ type: 'cancel', id: id });
  }

  removeFile(id: string): void {
    this.uploadInput.emit({ type: 'remove', id: id });
  }

  removeAllFiles(): void {
    this.uploadInput.emit({ type: 'removeAll' });
  }
}
