
import { Injectable } from '@angular/core';
import { RouterModule, Routes, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from 'rxjs';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError, AuthError } from 'msal';
import { Organization } from '../../classes/organization.class';
import { OauthGuard } from '../..//guards/oauth.guard';

@Injectable()
export class TrialEnvironmentGuard implements CanActivate {
    constructor(private oauthGuard: OauthGuard, private organization: Organization) {
    }

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

         let canActivate = this.oauthGuard.canActivate(next, state);
         let hasOrganization = await this.organization.hasOrganization()

         if (canActivate && !hasOrganization) {
             return true;
         }
         
         return false;         
    }    
}
