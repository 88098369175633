import { Injectable } from '@angular/core';
import { Client } from '@microsoft/microsoft-graph-client';
import { MsalService } from '@azure/msal-angular';


export class GraphProfile {
    givenName: string;
    surname: string;
    userPrincipalName: string;
    email: string;
    businessPhones: string[];
    mobilePhone: string;
}

export class GraphOrganization {
    displayName: string;
}

@Injectable({
    providedIn: 'root'
})
export class GraphService {
    private graphClient: Client;
    private headers: { Authorization: string; OrganizationId: string; };

    constructor(private auth: MsalService) {
        this.initClient();
    }

    async getProfile(): Promise<GraphProfile> {
        try {
            let result = await this.graphClient
                .api('/me')
                .select('givenName, surName, userPrincipalName, email, businessPhones, mobilePhone')
                .get();

            return result;
        } catch (error) {
            console.log(error);
        }
    }

    async getOrganization(): Promise<GraphOrganization> {
        try {
            let graphOrganization = await this.graphClient
                .api('/organization')
                .select('displayName')
                .get();                

            let result = new GraphOrganization();
            if (graphOrganization.value.length > 0) {            
                result.displayName = graphOrganization.value[0].displayName;
            }
            return result;
        } catch (error) {
            console.log(error);
        }
    }

    private async getAccessToken(): Promise<string> {
        var scopes = ["user.read", "profile"]

        let result = await this.auth.acquireTokenSilent({ scopes })
            .catch(() => {
                throw new Error('could not get access token');
            });

        if (result) {
            return result.accessToken;
        }
        return null;
    }

    private initClient(): void {
        this.graphClient = Client.init({
            authProvider: async (done: (reason: string, token: string) => void): Promise<void> => {
                const token: string | void = await this.getAccessToken()
                    .catch((reason: string) => {
                        done(reason, '');
                    });

                if (token) {
                    done('', token);
                } else {
                    done('Could not get an access token', '');
                }
            },
        });
    }
}