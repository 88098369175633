export const environment = {
  envName: 'dev',
  apiUrl: window.location.origin,
  redirectUri: 'https://dev.mijn.dias.nl',
  postLogoutRedirectUri: 'https://mijn.dev.dias.nl',
  domain: 'dias.nl',
  GoogleAnalyticsId: '',
  production: false,
  apiApplicationId: '13751274-5c6e-4125-ae1a-4673c36b8379',
  clientId: '9690aa6a-cf36-468f-9b48-dbddda19ff0e'

};