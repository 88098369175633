import { BehaviorSubject } from 'rxjs';
import { AuthorisationService } from '../shared/oauth/authorisation.service';
import { Injectable } from '@angular/core';
import { Organisation } from '../welcome/self-signup/self-signup.class';
import { MsalService } from '@azure/msal-angular';

@Injectable({
    providedIn: 'root',
  })
export class Organization {
    id: string;
    name: string;
    private isInitialized: boolean;
    private exists: boolean;
    isOrganisationSelected = new BehaviorSubject<boolean>(false);

    constructor(private authorisationService: AuthorisationService, msalService: MsalService) {        
    }
    
    private async getOrganizations() : Promise<any[]> {
        return await this.authorisationService.getOrganizations();
    }
    
    async hasOrganization(): Promise<boolean>  {
        if (!this.isInitialized) {
            await this.selectOrganization();
        }
        return this.exists;
    }    

    async selectOrganization() : Promise<Organization> {
        var allOrganizations = await this.authorisationService.getOrganizations();
        await this.setOrganization(allOrganizations[0]);
        return allOrganizations[0];
    }

    async selectOrganizationById(id: string) {
        var organization = (await this.getOrganizations()).find(x => x.id == id)
        this.id = organization.id;
        this.name = organization.name;
        await this.setOrganization(this);
    }

    async reset() {
        this.isInitialized = false;
        this.exists = undefined;
        this.isOrganisationSelected = new BehaviorSubject<boolean>(false);
        this.id = undefined;
        this.name = undefined;
        localStorage.removeItem('currentOrganizationId')
    }

    private async setOrganization(organization : Organization) {
        this.isInitialized = true;

        if (!organization) {
            this.exists = false;
            return;
        }

        localStorage.setItem('currentOrganizationId', organization.id);

        this.exists = true;
        this.id = organization.id;
        this.name = organization.name;

        this.isOrganisationSelected.next(true);
    }
}