<div class="container-trial" fxLayout="row">
  <div class="content">
    <h2>Welkom bij DIAS Advies</h2>
    <p style="font-size:17px">      
      Hier kunt u een DIAS Advies Cloud omgeving gratis en vrijblijvend aanmaken voor 30 dagen.<br />
      Vul hieronder uw gegevens in om direct aan de slag te gaan.<br />
    </p>
    <div fxLayout="row">
      <form (ngSubmit)="onSubmit()" [formGroup]="trialForm" fxLayout="column" fxFlex="650px">
        <mat-form-field floatPlaceholder="always">
          <input type="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" matInput placeholder="Inlognaam" formControlName="userName" value="{{trialForm.userName}}" required id="userName">
          <mat-error *ngIf="userName.hasError('pattern')">
            Inlognaam is ongeldig
          </mat-error>
          <mat-error *ngIf="userName.hasError('required')">
            Inlognaam is een <strong>verplicht</strong> veld.
          </mat-error>
        </mat-form-field>
        <mat-form-field floatPlaceholder="always">
          <input matInput type="text" placeholder="Voornaam" formControlName="firstName" value="{{trialForm.firstName}}"
            required id="firstName">
          <mat-error *ngIf="firstName.hasError('required')">
            Voornaam is een <strong>verplicht</strong> veld.
          </mat-error>
        </mat-form-field>
        <mat-form-field floatPlaceholder="always">
          <input matInput placeholder="Achternaam" formControlName="lastName" value="{{trialForm.lastName}}" required
            id="lastName">
          <mat-error *ngIf="lastName.hasError('required')">
            Achternaam is een <strong>verplicht</strong> veld.
          </mat-error>
        </mat-form-field>
        <mat-form-field floatPlaceholder="always">
          <select matNativeControl placeholder="Geslacht" formControlName="gender" >
            <option value="unknown"></option>
            <option value="male">Man</option>
            <option value="female">Vrouw</option>
          </select>      
        </mat-form-field>
        <mat-form-field floatPlaceholder="always">
          <input matInput placeholder="Bedrijfsnaam" aria-label="Bedrijfsnaam KVK" [matAutocomplete]="auto" formControlName="organizationName" value="{{trialForm.organizationName}}"
            required id="organizationName">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngIf="cocIsLoading" class="is-loading">Laden...</mat-option>
            <ng-container *ngIf="!cocIsLoading">
              <mat-option *ngFor="let cocSearchResult of cocSearchResults" [value]="cocSearchResult.handelsnaam">
                <div layout="row">
                  <span><b>{{cocSearchResult.handelsnaam}}</b> - KVK-nummer: {{cocSearchResult.dossiernummer}} - ({{cocSearchResult.plaats}})</span>
                </div>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
          <mat-error *ngIf="lastName.hasError('required')">
            Bedrijfsnaam is een <strong>verplicht</strong> veld.
          </mat-error>
        </mat-form-field>

        <mat-form-field floatPlaceholder="always">
          <input matInput type="text" placeholder="KVK-nummer" formControlName="cocNumber" value="{{trialForm.cocNumber}}"id="cocNumber"
          minlength="8" maxlength="8">
          <mat-error *ngIf="cocNumber.hasError('minlength')">
            KVK-nummer bestaat altijd uit 8 cijfers.
          </mat-error>
      </mat-form-field>

        <mat-form-field floatPlaceholder="always">
          <input matInput placeholder="Telefoonnummer" formControlName="phoneNumber" value="{{trialForm.phoneNumber}}" required
            id="phoneNumber">
            <mat-error *ngIf="phoneNumber.hasError('required')">
              Telefoonnummer is een <strong>verplicht</strong> veld.
            </mat-error>
          </mat-form-field>
        <mat-form-field floatPlaceholder="always">
          <input type="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$" matInput placeholder="Correspondentie e-mailadres" formControlName="email" value="{{trialForm.email}}" required id="email">
          <mat-error *ngIf="email.hasError('pattern')">
            Correspondentie e-mailadres is ongeldig
          </mat-error>
          <mat-error *ngIf="email.hasError('required')">
            Correspondentie e-mailadres is een <strong>verplicht</strong> veld.
          </mat-error>
        </mat-form-field>

        <mat-slide-toggle class="relation" formControlName="isExistingCustomer" labelPosition="before"
          disableRipple="true">Bent u een relatie van DIAS Software en heeft u een licentie voor Efdece of DIAS Advies?
        </mat-slide-toggle>

        <div *ngIf="isExistingCustomer">
          <div fxLayout="column">
            <div fxLayout="row">
              <mat-form-field floatPlaceholder="always" fxFill>
                <input matInput placeholder="Relatienummer" formControlName="customerNumber" value="{{trialForm.customerNumber}}"
                  name="customerNumber">
                <mat-error *ngIf="customerNumber.hasError('required')">
                  Relatienummer is een <strong>verplicht</strong> veld.
                </mat-error>
              </mat-form-field>
              <div class="tooltip">
                <mat-icon >info_outline</mat-icon>
                <span class="tooltiptext">U kunt uw relatienummer bij DIAS Software als volgt vinden:<br>
                  - Als u Efdece gebruikt dan kunt u uw relatienummer vinden door in het menu Bestand te kiezen voor
                  Gemeenschappelijke instellingen en vervolgens voor het tabblad Overige.<br>
                  - Als u DIAS Advies gebruikt dan vindt u het relatienummer door in het menu Systeem te kiezen voor
                  Licentiegegevens tonen.
                </span>
              </div>
            </div>
            <div fxLayout="row">
              <mat-form-field floatPlaceholder="always" fxFill>
                <input matInput type="password" placeholder="Wachtwoord" formControlName="customerPassword"
                  value="{{trialForm.customerPassword}}" name="customerPassword">
                <mat-error *ngIf="customerPassword.hasError('required')">
                  Wachtwoord is een <strong>verplicht</strong> veld.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="mat-checkbox-label">
          <mat-checkbox name="isTermsAccepted" formControlName="isTermsAccepted" labelPosition="after">Ik ben me ervan bewust dat ik een overeenkomst aanga voor 30 dagen voor dit proefabonnement met DIAS Software. Op deze overeenkomst zijn de algemene leveringsvoorwaarden van toepassing. Deze kunnen worden geraadpleegd op <a href="http://www.dias.nl">www.dias.nl</a>. Het doel van dit proefabonnement is kennis te nemen van de mogelijkheden en werking van DIAS Advies en niet voor het operationeel gebruik. Ik zal dan ook geen gegevens van reële personen verwerken in de software gedurende deze proefperiode. Zonder actieve licentie worden deze gegevens door DIAS Software binnen 3 maanden weer verwijderd. 
            Ik geef DIAS Software toestemming om mijn bovenstaande persoonsgegevens te verwerken voor het kunnen aanmaken van het proefabonnement en om gedurende de overeenkomst enkele e-mails te sturen om het product beter te leren kennen. DIAS Software verwerkt de contactgegevens om contact met mij op te nemen voor een evaluatiegesprek.
          </mat-checkbox>
        </div>
        <p></p>
        <div>
          <button type="submit" mat-raised-button color='primary' [disabled]="!trialForm.valid">Aanvragen</button>
        </div>
      </form>
      <!-- <form (ngSubmit)="onSubmit(registerForm)" [formGroup]="ngForm" #registerForm="ngForm" fxLayout="column" fxFlex="650px" novalidate>
          <p style="font-size:17px">
            Probeer DIAS Advies 30 dagen gratis en vrijblijvend
          </p>
 
          <mat-slide-toggle class="relation" (change)="existingCumstomer()" [checked]="isRelation" labelPosition="before" disableRipple="true">Bent u een relatie van DIAS Software en heeft u een licentie voor Efdece of DIAS Advies?</mat-slide-toggle>
  
          <div *ngIf="isRelation">
            <div fxLayout="column">
              <div fxLayout="row">
                <mat-form-field floatPlaceholder="always" fxFill>
                  <input matInput  placeholder="Relatienummer" [required]="isRelation" ngModel name="customerNumber">
                  <mat-error *ngIf="customerNumber.hasError('required')">
                    Relatienummer is een <strong>verplicht</strong> veld.
                  </mat-error>
  
                </mat-form-field>
                <mat-icon class="tooltip" style="    padding-top: 15px;
            
            color: #ef9f0e;
            cursor: pointer;">info_outline
                </mat-icon>
                <span class="tooltiptext">U kunt uw relatienummer bij DIAS Software als volgt vinden:<br>
                    Als u Efdece gebruikt dan kunt u uw relatienummer vinden door in het menu Bestand te kiezen voor Gemeenschappelijke instellingen en vervolgens voor het tabblad Overige. 
                    <br> Als u Dias Advies gebruikt dan vindt u het relatienummer door in het menu Systeem te kiezen voor Licentiegegevens tonen.</span>
  
            
              </div>
              <div fxLayout="row">
                <mat-form-field floatPlaceholder="always" fxFill>
                  <input matInput  type="password" placeholder="Wachtwoord" [required]="isRelation" ngModel name="customerPassword">
                  <mat-error *ngIf="customerPassword.hasError('required')">
                    Wachtwoord is een <strong>verplicht</strong> veld.
                  </mat-error>
                </mat-form-field>
              </div>
            </div>
          </div>
          <div class="mat-checkbox-label">          
                <mat-checkbox name="isTermsAccepted" [(ngModel)]="isTermsAccepted" labelPosition="after">Ik ben me ervan bewust dat ik een overeenkomst aanga voor 30 dagen voor dit proefabonnement met DIAS Software. Op deze overeenkomst zijn de algemene leveringsvoorwaarden van toepassen. Deze kunnen worden geraadpleegd op <a href="http://wwww.dias.nl">www.dias.nl</a>. Het doel van dit proefabonnement is kennis te nemen van de mogelijkheden en werking van DIAS Advies en niet voor het operationeel gebruik. Ik zal dan ook geen gegevens van reële personen verwerken in de software gedurende deze proefperiode. Zonder actieve licentie worden deze gegevens door DIAS Software binnen 3 maanden weer verwijderd. 
Ik geef DIAS Software toestemming om mijn bovenstaande persoonsgegevens te verwerken voor het kunnen aanmaken van het proefabonnement en om gedurende de overeenkomst enkele e-mails te sturen om het product beter te leren kennen. DIAS Software verwerkt de contactgegevens om contact met mij op te nemen voor een evaluatiegesprek.
</mat-checkbox>          
          </div>
          <p></p>
          <div>
            <button type="submit" mat-raised-button color='primary' [disabled]="!isTermsAccepted">Aanvragen</button>
          </div>
        </form> -->
      <div fxFlex="50%">
      </div>
    </div>
  </div>