import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm, Validators } from '@angular/forms';
import { CommunicationService } from '../../shared/services/communication.service';

@Component({
  selector: 'app-self-signup',
  templateUrl: './self-signup.component.html',
  styleUrls: ['./self-signup.component.scss']
})
export class SelfSignupComponent implements OnInit {
  private EMAIL_REGEX = '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$';
  public isRelation = false;
  public firstName = new FormControl('', Validators.required);
  public lastName = new FormControl('', Validators.required);
  public organizationName = new FormControl('', Validators.required);
  public phoneNumber = new FormControl('', Validators.required);
  public email = new FormControl('');

  constructor(private communicationService: CommunicationService) { }

  ngOnInit() {
  }

  onSubmit(f: NgForm) {
    if (f.valid) {
      this.communicationService.sendContact(f.value);
    }
  }

}
