import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { CommonModule } from '@angular/common';

import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { HttpClientModule } from '@angular/common/http';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PendingChangesGuard } from '../guards/PendingChanges.guard';
import { FlexLayoutModule } from '@angular/flex-layout';
import { SharedModule } from '../shared/shared.module';
import { MainComponent } from './main.component';
import { MainRoutes } from './main.routing';
import { HeaderComponent } from './header/header.component';
import { StartComponent, ResetDatabaseDialog } from './start/start.component';
import { OauthGuard } from '../guards/oauth.guard';
import { NgxUploaderModule } from 'ngx-uploader';
import { StorageModule } from '../modules/browser-storage';
import { UploadComponent } from './upload/upload.component';
import { DbcheckComponent } from './dbcheck/dbcheck.component';
import { EnvprocessComponent } from './envprocess/envprocess.component';
import { TrialEnvironmentComponent } from './trial-environment/trial-environment.component';
import { TrialEnvironmentGuard } from './trial-environment/trial-environment.guard';
import { StartGuard } from './start/start.guard';
import { SelfSignUpGuard } from '../guards/selfsignup.guard.';

@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    FormsModule,
    FlexLayoutModule,
    ReactiveFormsModule,
    // MaterialModule,
    BrowserAnimationsModule,
    FlexLayoutModule,
    SharedModule,
    StorageModule,
    NgxUploaderModule,
    HttpClientModule,
    MatInputModule,
    MatAutocompleteModule,
    MainRoutes
  ],
  declarations: [
    MainComponent,
    HeaderComponent,
    StartComponent,
    UploadComponent,
    DbcheckComponent,
    EnvprocessComponent,
    ResetDatabaseDialog,
    TrialEnvironmentComponent
  ],
  entryComponents: [StartComponent, ResetDatabaseDialog, TrialEnvironmentComponent],
  providers: [
    OauthGuard,
    PendingChangesGuard,
    StartGuard,
    TrialEnvironmentGuard,
    SelfSignUpGuard
  ],
  bootstrap: [MainComponent]
})
export class MainModule { }
