import { NgModule } from '@angular/core';

import { LocalStorage } from "./local-storage";
export { LocalStorage } from "./local-storage";
import { SessionStorage } from "./session-storage";
export { SessionStorage } from "./session-storage";

@NgModule({
  providers: [LocalStorage, SessionStorage]
})
export class StorageModule {}