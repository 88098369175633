import { Component, OnInit } from '@angular/core';
import { FormControl, NgForm, Validators, FormGroup, RequiredValidator, AbstractControl } from '@angular/forms';
import { CommunicationService } from '../../shared/services/communication.service';
import { MsalService } from '@azure/msal-angular';
import { Account } from 'msal';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { GraphProfile, GraphOrganization, GraphService } from '../../shared/services/graph.service';
import { RequiredIfValidator } from '../../shared/validators/requiredif.validator';
import { delay, debounceTime, tap, switchMap, finalize } from 'rxjs/operators';
import { Organization } from '../../classes/organization.class';
import { environment } from '../../../environments/environment';
import { KvkResult } from '../../classes/kvkResult.class';

@Component({
  selector: 'app-trial-environment',
  templateUrl: './trial-environment.component.html',
  styleUrls: ['./trial-environment.component.scss']
})
export class TrialEnvironmentComponent implements OnInit {
  private EMAIL_REGEX = '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$';
  public account: Account;
  public profile: GraphProfile;
  public organization: GraphOrganization;
  public isExistingCustomer: boolean;

  public trialForm;

  get firstName() { return this.trialForm.get('firstName'); }
  get lastName() { return this.trialForm.get('lastName'); }
//  get organizationName() { return this.trialForm.get('organizationName'); }
  get userName() { return this.trialForm.get('userName'); }
  get email() { return this.trialForm.get('email'); }
  get phoneNumber() { return this.trialForm.get('phoneNumber'); }
  get customerNumber() { return this.trialForm.get('customerNumber'); }
  get customerPassword() { return this.trialForm.get('customerPassword'); }
  get cocNumber() { return this.trialForm.get('cocNumber'); }

  constructor(private http: HttpClient, private communicationService: CommunicationService, private authService: MsalService, private route: ActivatedRoute, private router: Router, private graphService: GraphService, private cOrganization: Organization) { }

  cocIsLoading = false;
  cocSearchResults: KvkResult[];

  async ngOnInit() {

    this.account = this.authService.getAccount();
    
    this.profile = await this.graphService.getProfile();
    this.organization = await this.graphService.getOrganization();

    let phoneNumber = this.profile.businessPhones.length > 0 ? this.profile.businessPhones[0] : this.profile.mobilePhone ? this.profile.mobilePhone : '';

    var userNameCtrl = new FormControl(this.profile.userPrincipalName.toLowerCase(), Validators.required);
    userNameCtrl.disable();

    var cocNumberCtrl = new FormControl();

    var organizationNameCtrl = new FormControl("", Validators.required);
    organizationNameCtrl.valueChanges
      .pipe(
        debounceTime(500),
        tap((value: string) => {
          if (value != "" && this.cocSearchResults != undefined && this.cocSearchResults.length > 0) {
            var item = this.cocSearchResults.find(x => x.handelsnaam == value);
            if (item != undefined){ 
              cocNumberCtrl.setValue(item.dossiernummer);
            }
          }
          this.cocSearchResults = [];
          this.cocIsLoading = true;
        }),
        switchMap((value: string) => this.communicationService.searchKvk(value)
          .pipe(
            finalize(() => {
              this.cocIsLoading = false;
            })
          )
        )
      )
      .subscribe(data => {
        this.cocSearchResults = data;
        console.log(this.cocSearchResults);              
      })

    var emailCtrl = new FormControl(this.profile.userPrincipalName.toLowerCase(), Validators.required);

    this.trialForm = new FormGroup({
      userName: userNameCtrl,
      firstName: new FormControl(this.profile.givenName, Validators.required),
      lastName: new FormControl(this.profile.surname, Validators.required),
      gender: new FormControl(),
      organizationName: organizationNameCtrl,
      cocNumber: cocNumberCtrl,
      phoneNumber: new FormControl(phoneNumber, Validators.required),
      email: emailCtrl,
      isExistingCustomer: new FormControl(false),
      customerNumber: new FormControl(null, RequiredIfValidator(() => this.trialForm.get('isExistingCustomer').value)),
      customerPassword: new FormControl(null, RequiredIfValidator(() => this.trialForm.get('isExistingCustomer').value)),
      isTermsAccepted: new FormControl(false, Validators.requiredTrue)
    });

    this.trialForm.get('isExistingCustomer').valueChanges
      .subscribe(value => {
        this.trialForm.get('customerNumber').updateValueAndValidity();
        this.trialForm.get('customerPassword').updateValueAndValidity();
        this.isExistingCustomer = value;
      });
  }

  async onSubmit() {
    if (!this.trialForm.isExistingCustomer) {
      this.trialForm.customerNumber = null;
      this.trialForm.customerPassword = null;
    }

    let formObj = this.trialForm.getRawValue();
    let json = JSON.stringify(formObj);

    try {
      let newOrganizationId = await this.communicationService.createTrialEnvironment(json);    
      await this.cOrganization.reset();
      console.log('redirecting');
      window.location.replace(environment.redirectUri);  
    }
    catch {

    }
  }

}