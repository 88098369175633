import { AuthorisationService } from './shared/oauth/authorisation.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { CommunicationService } from './shared/services/communication.service';
//import { NgcCookieConsentService } from 'ngx-cookieconsent';
import { NgcInitializeEvent, NgcStatusChangeEvent, NgcNoCookieLawEvent } from 'ngx-cookieconsent';
import { Subscription } from 'rxjs';
import { BroadcastService, MsalService } from '@azure/msal-angular';
import { Logger, CryptoUtils } from 'msal';
import { environment } from '../environments/environment';
import { Organization } from './classes/organization.class';
import { GraphService } from './shared/services/graph.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  private popupOpenSubscription: Subscription;
  private popupCloseSubscription: Subscription;
  private initializeSubscription: Subscription;
  private statusChangeSubscription: Subscription;
  private revokeChoiceSubscription: Subscription;
  private noCookieLawSubscription: Subscription;
  isIframe = false;
  public loggedIn = false;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private broadcastService: BroadcastService,
    private authService: MsalService,
    private communicationService: CommunicationService,
    private authorisationService: AuthorisationService,
    private organization: Organization,
    private graphService: GraphService
    //    private ccService: NgcCookieConsentService,
  ) { }

  ngOnInit(): void {

    /* rdj 01/07/2019: disable cookie consent
    - it is not accepted by Unit4 and must be OneTrust which requires an insane amount of time for content implementation
    - for now only use google tag with anonymous data    

    this.ccService.getConfig().content = this.ccService.getConfig().content || {} ;
    
    // Override default messages with the translated ones
//    this.ccService.getConfig().content.header = 'header'
    this.ccService.getConfig().content.dismiss = 'dismiss'
    this.ccService.getConfig().content.allow = 'Akkoord'
    this.ccService.getConfig().content.deny = 'Weiger'
    //this.ccService.getConfig().content.link = 
    this.ccService.getConfig().content.policy = 'Cookiebeleid'

    this.ccService.destroy();//remove previous cookie bar (with default messages)
    this.ccService.init(this.ccService.getConfig()); // update config with translated messages
*/

    //    this.analyticsService.allowedByCookie = true;
    //    this.analyticsService.anonymous = true;

    //    this.analyticsService.allowedByCookie = this.ccService.hasConsented();
    //    this.analyticsService.anonymous = false;
    //    this.analyticsService.init();

    if (window.location.hostname == "test.unit4scenario.nl") {
      window.location.href = "https://mijn.test.dias.nl";
    }
    if (window.location.hostname == "www.unit4scenario.nl" || window.location.hostname == "unit4scenario.nl") {
      window.location.href = "https://mijn.dias.nl";
    }

    this.isIframe = window !== window.parent && !window.opener;
    this.checkoutAccount();

    this.broadcastService.subscribe('msal:loginSuccess', () => {
      this.checkoutAccount();

      this.organization.isOrganisationSelected.subscribe(isSelected => {
        if (!isSelected) {
          var currentOrganizationId: string = this.organization.id;
          this.organization.selectOrganization();
        }
      });
    });

    this.authService.handleRedirectCallback((authError, response) => {
      if (authError) {
        console.error('Redirect Error: ', authError.errorMessage);
        return;
      }

      console.log('Redirect Success: ', response);
    });

    this.authService.setLogger(new Logger((logLevel, message, piiEnabled) => {
      console.log('MSAL Logging: ', message);
    }, {
      correlationId: CryptoUtils.createNewGuid(),
      piiLoggingEnabled: environment.envName == 'dev'
    }));

    /*    
        this.popupCloseSubscription = this.ccService.popupClose$.subscribe(
          () => {
            this.analyticsService.allowedByCookie = this.ccService.hasConsented();
            this.analyticsService.init();
          });
    */
  }

  checkoutAccount() {
    this.loggedIn = !!this.authService.getAccount();
  }

  login() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1 || window.navigator.userAgent.indexOf('Edge') > -1;
//    const apiScope = 'api://' + environment.apiApplicationId + '/access_as_user';
    const loginRequest = {
      scopes: [
        "user.read",
        "openid",
        "profile",
        "email"
      ],
      additionalScopesToConsent: ['user.read'],
      prompt: 'select_account'
    };

    if (isIE) {
      this.authService.loginRedirect(loginRequest);      
    } else {
      this.authService.loginPopup(loginRequest).then(
        (loginResponse) => {
          this.organization.reset();
          window.location.replace(environment.redirectUri);
        }).catch(function (error) {
          console.log(error);
        });
    }
  }

  logout() {
    this.authService.logout();
  }
}

