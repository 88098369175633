import { Component, OnInit } from '@angular/core';
import { AuthorisationService } from '../../shared/oauth/authorisation.service';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public pageTitle = "Start";
  public loggedIn = false;

  constructor(private authService: MsalService, private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd && this.activatedRoute.firstChild.snapshot.data) {
        let params: any = this.activatedRoute.firstChild.snapshot.data;
        if (params.title) {
          this.pageTitle = params.title;
        }
      }
    });

    this.checkoutAccount();
  }

  checkoutAccount() {
    this.loggedIn = !!this.authService.getAccount();
  }
  
  logout() {
    this.authService.logout();
  }
}

