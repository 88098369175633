import { Component, OnInit,Input } from '@angular/core';
import { LocalStorage } from '../../modules/browser-storage';
@Component({
  selector: 'app-envprocess',
  templateUrl: './envprocess.component.html',
  styleUrls: ['./envprocess.component.scss']
})
export class EnvprocessComponent implements OnInit {
 
  @Input()
  public status ={"value":{"state":"3","stateDescription":"U heeft nog geen omgeving","stateChangedOn":"9/18/2017 12:06:28 PM"}};
  constructor( private localStorage: LocalStorage) { }

  ngOnInit() {
      //Check for owner the status of the system 
      if (this.localStorage.getObject('status') != null) {
        this.status = this.localStorage.getObject('status');
      }
  }
  public get environmentStatus() {
    return (Number(this.status.value.state));
  }
}
