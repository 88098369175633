import { Component, ViewEncapsulation, OnInit } from '@angular/core';
import { CommunicationService } from '../shared/services/communication.service';
import { LocalStorage } from '../modules/browser-storage/local-storage';
import { Router, ActivatedRoute } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { AuthorisationService } from '../shared/oauth/authorisation.service';
import { Organization } from '../classes/organization.class';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit {
  public isNavToggled = false;

  public routerMain: Array<any> = [
    { routerLink: '/start', 'name': 'Start', icon: 'fa-home' }
  ];
  constructor(private communicationService: CommunicationService, private authService: AuthorisationService,
    private localStorage: LocalStorage, private organization: Organization) { }

  ngOnInit() {
    if (this.authService.getCurrentUser() != null && this.organization.hasOrganization()) {
      this.communicationService.environmentStatus().subscribe(result => {
        this.localStorage.setObject('status', result);
      });
    }
  }

  public GetMenuToggleIcon(): string {
    if (!this.isNavToggled) {
      return 'keyboard_arrow_right';
    }
    return 'keyboard_arrow_left';
  }

}
