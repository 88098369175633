<h2 mat-dialog-title>Weet u het zeker?</h2>
<mat-dialog-content>
  <p>Uw bestaande adviezen en relaties in Dias Advies Cloud worden verwijderd.</p>
  <p>Deze actie kan niet ongedaan worden gemaakt.</p>
  <p>Na het resetten van uw database krijgt u de mogelijkheid een nieuwe database aan te maken of uw bestaande lokale Dias Advies database te uploaden.</p>
  
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-button mat-dialog-close color="warn">Annuleren</button>
  <button mat-button [mat-dialog-close]="true" cdkFocusInitial color="primary">OK</button>
</mat-dialog-actions>
