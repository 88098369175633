<header fxLayout="column">
    <mat-toolbar class="mat-elevation-z2">
            <h2 class="u4-page-title"> {{pageTitle}}</h2>
      
        <span fxFlex></span>
      
        <button mat-icon-button  id="buttonUitloggen"  matTooltip="Uitloggen" mat-offset="0 72" (click)="logout()" >
          <mat-icon class="fa fa-sign-out"></mat-icon>          
        </button >

        <!-- <span class="fill-remaining-space"></span>
        <button mat-raised-button *ngIf="loggedIn" (click)="logout()">Logout</button> -->
    
    </mat-toolbar>
</header>