import { Component, Injectable, EventEmitter, OnInit, Input} from '@angular/core';
import { trigger, state, style, animate, transition} from '@angular/animations';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
// import { Observable } from 'rxjs/Observable';
import { TimerObservable } from 'rxjs/observable/TimerObservable';

@Component({
  selector: 'app-message',
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss'],
  animations: [
    trigger('messageTrigger', [
        state('open', style({ display: 'block', opacity: 1, top: 0 })),
        state('closed', style({ display: 'none', opacity: 0, top: '-50px'})),
        transition('open => closed', animate('300ms ease-in')),
        transition('closed => open', animate('300ms ease-in-out'))
    ])]
})

@Injectable()
export class MessageComponent implements OnInit {
    statusMessage: Message;
    messageState = 'closed';
    static globalMessage: MessageComponent = null;
    static eventEmitter: EventEmitter<any> = new EventEmitter();
    @Input() message: MessageComponent;

    ngOnInit() {
        if (this.message) {
            MessageComponent.globalMessage = this.message;
        }
    }

    show(message: Message) {
        MessageComponent.globalMessage.statusMessage = message;
        MessageComponent.globalMessage.messageState = "open";

        let timer = TimerObservable.create(7000);

        timer.subscribe(t => {
            MessageComponent.globalMessage.messageState = "closed";
        });
    }
}

export class Message
{
    constructor(
        public status: MessageStatus,
        public statusText: string
    ) { }
}

export class MessageStatus {
    constructor(
        private result: string
    ) { }

    get text(): string {
        return this.result;
    }

    static Info: MessageStatus = new MessageStatus("info");
    static Error: MessageStatus = new MessageStatus("error");
    static Success: MessageStatus = new MessageStatus("success");
    static Warning: MessageStatus = new MessageStatus("warning");
}

