import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthorisationService } from '../shared/oauth/authorisation.service';
import { CommunicationService } from '../shared/services/communication.service';
import { MsalService } from '@azure/msal-angular';
import { environment } from '../../environments/environment';
import { Organization } from '../classes/organization.class';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {

  constructor(private authService: MsalService,
    private organization: Organization) { }

  ngOnInit() {
  }

  login() {
    const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1 || window.navigator.userAgent.indexOf('Edge') > -1;
    const loginRequest = {
      scopes: [
        "user.read",
        "openid",
        "profile",
        "email"
      ],
      additionalScopesToConsent: ['user.read'],
      prompt: 'select_account'
    }        

    if (isIE) {
      this.authService.loginRedirect(loginRequest);
    } else {
      this.authService.loginPopup(loginRequest).then(
        (loginResponse) => {
          this.organization.reset();
          window.location.replace(environment.redirectUri);
        }).catch(function (error) {
          console.log(error);
        });
    }
  }

}
