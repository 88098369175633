<div class="container-welcome" fxLayout="row">
  
  <div class="left"></div>
  <div class="middle">    
    <h1>Contactformulier proeflicentie</h1>
    <div fxLayout="row">
      <form (ngSubmit)="onSubmit(registerForm)" #registerForm="ngForm" fxLayout="column" novalidate>
        <p style="font-size:17px">
          U heeft gekozen om liever samen met een medewerker van DIAS Software een proeflicentie aan te vragen.
          Vul hier uw gegevens in waarop u bereikbaar bent.
        </p>
        <mat-form-field floatPlaceholder="always">
          <input matInput  placeholder="Voornaam" ngModel required id="firstName" name="firstName">
          <mat-error *ngIf="firstName.hasError('required')">
            Voornaam is een <strong>verplicht</strong> veld.
          </mat-error>
        </mat-form-field>
        <mat-form-field floatPlaceholder="always">
          <input matInput  placeholder="Achternaam" ngModel required id="lastName" name="lastName">
          <mat-error *ngIf="lastName.hasError('required')">
            Achternaam is een <strong>verplicht</strong> veld.
          </mat-error>
        </mat-form-field>
        <mat-form-field floatPlaceholder="always">
          <input matInput  placeholder="Bedrijfsnaam" ngModel id="organizationName" name="organizationName">
          <mat-error *ngIf="organizationName.hasError('required')">
            Bedrijfsnaam is een <strong>verplicht</strong> veld.
          </mat-error>
        </mat-form-field>
        <mat-form-field floatPlaceholder="always">
          <input matInput  placeholder="Telefoonnummer" ngModel required id="phoneNumber" name="phoneNumber">
          <mat-error *ngIf="phoneNumber.hasError('required')">
            Telefoonnummer is een <strong>verplicht</strong> veld.
          </mat-error>
        </mat-form-field>
        <mat-form-field floatPlaceholder="always">
          <input type="email" pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$" matInput  placeholder="E-mailadres" ngModel
            id="email" name="email">
          <mat-error *ngIf="email.hasError('pattern')">
            E-mailadres is ongeldig
          </mat-error>
        </mat-form-field>
        <!-- <mat-slide-toggle class="relation" (change)="existingCumstomer()" [checked]="isRelation" labelPosition="before" disableRipple="true">Bent u een relatie van DIAS Software en heeft u een licentie voor Efdece of Dias Advies?</mat-slide-toggle>

        <div *ngIf="isRelation">
          <div fxLayout="column">
            <div fxLayout="row">
              <mat-form-field floatPlaceholder="always" fxFill>
                <input matInput  placeholder="Relatienummer" [required]="isRelation" ngModel name="customerNumber">
                <mat-error *ngIf="customerNumber.hasError('required')">
                  Relatienummer is een <strong>verplicht</strong> veld.
                </mat-error>

              </mat-form-field>
              <mat-icon class="tooltip" style="    padding-top: 15px;
          
          color: #ef9f0e;
          cursor: pointer;">info_outline
              </mat-icon>
              <span class="tooltiptext">U kunt uw relatienummer bij DIAS Software als volgt vinden:<br>
                  Als u Efdece gebruikt dan kunt u uw relatienummer vinden door in het menu Bestand te kiezen voor Gemeenschappelijke instellingen en vervolgens voor het tabblad Overige. 
                  <br> Als u DIAS Advies gebruikt dan vindt u het relatienummer door in het menu Systeem te kiezen voor Licentiegegevens tonen.</span>

          
            </div>
            <div fxLayout="row">
              <mat-form-field floatPlaceholder="always" fxFill>
                <input matInput  type="password" placeholder="Wachtwoord" [required]="isRelation" ngModel name="customerPassword">
                <mat-error *ngIf="customerPassword.hasError('required')">
                  Wachtwoord is een <strong>verplicht</strong> veld.
                </mat-error>
              </mat-form-field>
            </div>
          </div>
        </div> -->
        <p></p>
        <div>
          <button type="submit" mat-button color='primary'>Verstuur</button>
        </div>
      </form>
    </div>
  </div>
  <div class="right"></div>