
import { Injectable } from '@angular/core';
import { RouterModule, Routes, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from 'rxjs';
import { AuthorisationService } from '../shared/oauth/authorisation.service';
import { Organization } from '../classes/organization.class';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError, AuthError } from 'msal';
import { environment } from '../../environments/environment';

@Injectable()
export class OauthGuard implements CanActivate {
    constructor(private oauthService: AuthorisationService, private authService: MsalService, private msalGuard: MsalGuard, private organization: Organization, private router: Router) {
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        if (this.oauthService.isSelfSignUp) {
            return false;
        }
        let currentUser = this.oauthService.getCurrentUser();

        if (this.router.url.endsWith('/proeflicentie/advies') || this.router.url.endsWith('/welkom') ) {
            if (currentUser != undefined) {
                this.router.navigate(['/start']);
                return false;
            }
            return true;
        }
        else {
            let currentUser = this.oauthService.getCurrentUser();

            if (currentUser != undefined) {
                var consentScopes = [
                    "api://"+environment.apiApplicationId+"/access_as_user"
                ];
                
                let result = this.authService.acquireTokenSilent({
                    scopes: consentScopes
                })
                    .then(() => true)
                    .catch((error: AuthError) => {
                        if (InteractionRequiredAuthError.isInteractionRequiredError(error.errorCode)) {
                            this.authService.getLogger().info(`Interaction required error in OauthGuard, prompting for interaction.`);
                            this.authService.loginPopup({scopes: consentScopes}).then(() => this.router.navigate([this.router.url]));
                        }

                        this.authService.getLogger().error(`Non-interaction error in OauthGuard: ${error.errorMessage}`);
                        throw error;
                    });

                return result;
            } else {
                this.router.navigate([`/welkom`]);
                return false;
            }

        }
    }
}
