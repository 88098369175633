import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LoaderComponent } from './loader/loader.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MessageComponent } from './message/message.component';
import { AuthorisationService } from './oauth/authorisation.service';
import { CommunicationService } from './services/communication.service';
import { HttpClientModule } from '@angular/common/http'
import { TitleService } from './services/title.service';

import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatToolbarModule, MatToolbar } from '@angular/material/toolbar';

import { FormsModule } from '@angular/forms';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        MatSlideToggleModule ,
        MatButtonModule,
        MatCheckboxModule,
        MatSidenavModule,
        MatListModule,
        MatToolbarModule,
        MatDialogModule
    ],
  declarations: [
        LoaderComponent,
        MessageComponent
    ],
  providers: [LoaderComponent, MessageComponent, AuthorisationService, CommunicationService, TitleService],
  exports: [LoaderComponent, MessageComponent, MatProgressBarModule , MatCardModule, MatListModule,MatSlideToggleModule,MatIconModule,MatInputModule, MatButtonModule, MatCheckboxModule,MatSidenavModule,MatToolbarModule,MatDialogModule]
})
export class SharedModule { }