
import { Injectable } from '@angular/core';
import { RouterModule, Routes, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from 'rxjs';
import { AuthorisationService } from '../shared/oauth/authorisation.service';
import { Organization } from '../classes/organization.class';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError, AuthError } from 'msal';
import { environment } from '../../environments/environment';

@Injectable()
export class SelfSignUpGuard implements CanActivate {
    constructor(private oauthService: AuthorisationService, private authService: MsalService, private msalGuard: MsalGuard, private organization: Organization, private router: Router) {
    }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        if (this.oauthService.isSelfSignUp) {
            return false;
        }
        let currentUser = this.oauthService.getCurrentUser();

        if (currentUser != undefined) {
            this.router.navigate(['/start']);
            return false;
        }
        return true;
    }
}
