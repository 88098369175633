
import {interval as observableInterval,  Observable } from 'rxjs';

import {catchError, switchMap, map} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { Router } from '@angular/router';
import { AuthorisationService } from '../../shared/oauth/authorisation.service';
import 'rxjs/Rx';
import { environment } from '../../../environments/environment';
import { LoaderComponent } from '../loader/loader.component';
import { MessageComponent, Message, MessageStatus } from '../message/message.component';
import { MsalService } from '@azure/msal-angular';
import { Organization } from '../../classes/organization.class';
import { KvkResult } from '../../classes/kvkResult.class';

@Injectable()

export class CommunicationService {
  private headers = new Headers({ 'Content-Type': 'application/json' , 'Accept': 'application/json'});
  private headersIdToken = new Headers({ 'Content-Type': 'application/json' });
  private baseUrl = environment.apiUrl;

  constructor(private httpClient: HttpClient, 
    private router: Router,
    private messageComponent: MessageComponent,
    private authService : MsalService,
    private loader: LoaderComponent,
    private organization: Organization) { }

    get httpOptions() {
      return { "headers": new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json'}),
          withCredentials : true
      };
    };
  
  private readCookie(name) {
    name += '=';
    for (var ca = document.cookie.split(/;\s*/), i = ca.length - 1; i >= 0; i--)
        if (!ca[i].indexOf(name))
            return ca[i].replace(name, '');
    return '';
  }

  async sendContact(singup) {
    const url = `${this.baseUrl + '/contact'}`;
    let body = JSON.stringify(singup);
    this.loader.show();
    var response = await this.httpClient
      .post<Response>(url, body, this.httpOptions)
      .pipe(catchError(err => this.handleError(err)))
      .toPromise<Response>();
    this.loader.done();
    window.location.replace(environment.apiUrl + '/welkom/signedup');
  }

  async createTrialEnvironment(json: string) {
    const url = `${this.baseUrl + '/createTrialEnvironment'}`;
    this.loader.show();
    await this.httpClient
      .post<string>(url, json, this.httpOptions)
      .pipe(catchError(err => this.handleError(err)))
      .toPromise<any>();    
    this.loader.done();
  }

  async getTenants(): Promise<[string, string[]][]> {
    const url = `${this.baseUrl + "/tenants"}`;
    return await this.httpClient    
      .get<boolean>(url, this.httpOptions)
      .pipe(catchError(err => this.handleError(err)))
      .toPromise<[string, string[]][]>();
  }

  createOrUpdateEnvironment() {
    const url = `${this.baseUrl + '/createOrUpdateEnvironment'}`;

    return this.httpClient
      .post(url, null, this.httpOptions)
      .map((res: Response) => { 
        console.log("Created environment");
       })
      .pipe(catchError(err => this.handleError(err)));
  }
  
  resetEnvironment () : Observable<any> {
    const url = `${this.baseUrl + '/resetEnvironment '}`;
    return this.httpClient    
    .post(url, null, this.httpOptions)
    .pipe(catchError(err => this.handleError(err)))
  }

  environmentStatus () : Observable<any> {
    const url = `${this.baseUrl + '/environmentStatus '}`;
    return  this.httpClient
    .get(url, this.httpOptions)    
    .pipe(catchError(err => this.handleError(err)))
  }

  environmentStatusLoop () : Observable<any> {
    const url = `${this.baseUrl + '/environmentStatus '}`;
    return observableInterval(5000)
    .pipe(switchMap(() =>  this.httpClient
      .get(url, this.httpOptions)      
      .pipe(catchError(err => this.handleError(err)))
    ));
  }
  
  async isDatabaseUploaded(): Promise<boolean> {
    const url = `${this.baseUrl + "/isDatabaseUploaded"}`;
    return await this.httpClient    
      .get<boolean>(url, this.httpOptions)
      .pipe(catchError(err => this.handleError(err)))
      .toPromise<boolean>();
  }

  async isOrganizationAdmin(): Promise<boolean> {
    const url = `${this.baseUrl + "/isOrganizationAdmin"}`;
    return await this.httpClient    
      .get<boolean>(url, this.httpOptions)
      .pipe(catchError(err => this.handleError(err)))
      .toPromise<boolean>();
  }

  searchKvk(name: string): Observable<any> {
    const url = `${this.baseUrl + "/CoC/search?searchValue="+name}`;
    return this.httpClient
      .get<any>(url, this.httpOptions)
      .pipe(catchError(err => this.handleError(err)));
  }

  private handleError(error: any): Promise<any> {
    this.loader.done();

    if (error instanceof Response) {
      let errMsg;
      if (error.status === 401) {
        //window.location.href = `${this.baseUrl + '/authenticate'}`;
        this.router.navigate([`/welcome`]);
        return Promise.resolve();
      }
      if (error.status === 419) { // DuplicateSubscription
        errMsg = 'Er is al een account met dit e-mailadres aanwezig';
      } else if (error.status === 420) { // DuplicateCustomer
        errMsg = 'Uw relatienummer is al geregistreerd. Neem binnen uw organisatie contact op met de beheerder van DIAS Advies om u als gebruiker toe te voegen';
      } else if (error.status === 421) { // DuplicateEmail
        errMsg = 'Uw e-mailadres is al geregistreerd onder een andere organisatie. Wilt u een kantoor toevoegen in DIAS Advies dan kunt u, nadat u bent ingelogd als beheerder, dit doen in het menu Systeem - Kantoren';
      } else if (error.status === 422) { // DuplicateOrganisation
        errMsg = 'Uw bedrijf heeft al een licentie voor DIAS Advies. Vraag binnen uw organisatie aan de beheerder van DIAS Advies om u als gebruiker toe te voegen';
      } else if (error.status === 423) { // InvalidCustomerCredentials
        errMsg = 'Het klantnummer en wachtwoord combinatie is onjuist';
      } else if (error.status === 424) { // RegistratieNotFound
        errMsg = 'U bent niet gevonden als beheerder van een cloud omgeving';
      } else if (error.status === 425) {
        errMsg = '...';
      } else if (error.status === 426) {
        errMsg = '...';
      } else if (error.status === 502) {
        return;
      }else {
        errMsg = 'Er heeft zich een fout voorgedaan';
      }

      this.messageComponent.show(new Message(MessageStatus.Error, errMsg));
    }

    console.error('An error occurred', error);
    console.log('ERROR');
    return Promise.reject(error.message || error);
  }

  private extractData(res: Response) {
    let body = res.json();
    return body || {};
  }

  private redirect(res: Response) {
    this.loader.done();
    window.location.href = res.toString();
  }
}
