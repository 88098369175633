import { Injectable } from '@angular/core';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { AuthorisationService } from '../shared/oauth/authorisation.service';
import { Organization } from '../classes/organization.class';
import { Organisation } from '../welcome/self-signup/self-signup.class';

@Injectable()
export class HttpOrganizationInterceptor implements HttpInterceptor {

    constructor(private authorisationService: AuthorisationService, private organization: Organization) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        if (!request.url.endsWith('/organizations')) {        
            this.organization.isOrganisationSelected.subscribe(isSelected => {
                if (!isSelected) {
                    this.organization.selectOrganization();
                }
            });
            
            var currentOrganizationId: string = localStorage.getItem('currentOrganizationId');
            
            if (currentOrganizationId) {
                request = request.clone({ headers: request.headers.set('OrganizationId', currentOrganizationId) });
            }
        }

        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    console.log('event--->>>', event);
                }
                return event;
            }));
    }
}