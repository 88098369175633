import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule, Self } from '@angular/core';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

import { FlexLayoutModule } from '@angular/flex-layout';

import { AppRoutingModule } from './app.routing';
import { AppComponent } from './app.component';
import { RouterModule } from '@angular/router';

import { WelcomeModule } from './welcome/welcome.module';
import { SharedModule } from './shared/shared.module';
import { MainModule } from './private/main.module';
import { ToastrModule } from 'ngx-toastr';

import { InvitedComponent } from './welcome/invited/invited.component'

import {
  MsalModule,
  MsalInterceptor,
  MSAL_CONFIG,
  MSAL_CONFIG_ANGULAR,
  MsalService,
  MsalAngularConfiguration
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Configuration } from 'msal';
import { HttpOrganizationInterceptor } from './interceptors/httporganizationinterceptor';
//import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { environment } from '../environments/environment';
import { WelcomeComponent } from './welcome/welcome.component';
import { SelfSignupComponent } from './welcome/self-signup/self-signup.component';
import { reduce } from 'rxjs/operators';
import { SignedupComponent } from './welcome/signedup/signedup.component';
import { StartComponent } from './private/start/start.component';
import { TrialEnvironmentComponent } from './private/trial-environment/trial-environment.component';

/*
const cookieConfig:NgcCookieConsentConfig = {
  cookie: {
    domain: environment.domain,    
  },
  position: 'top',
  palette: {
    popup: {
      background: '#585858'
    },
    button: {
      background: '#00f12c'
    }
  },
  theme: 'classic',
  type: 'opt-out',
  layout: 'my-custom-layout',
  layouts: {
    "my-custom-layout": '{{messagelink}}{{compliance}}'
  },
  elements:{
    messagelink: `
    <span id="cookieconsent:desc" class="cc-message"> {{message}} 
    <a aria-label="meer informatie over onze cookies" tabindex="3" class="cc-link" href="{{cookiePolicyHref}}" target="_blank">{{cookiePolicyLink}}</a>, 
    <a aria-label="meer informatie over onze privacy policy" tabindex="4" class="cc-link" href="{{privacyPolicyHref}}" target="_blank">{{privacyPolicyLink}}</a> en onze
    <a aria-label="meer informatie over onze algemene voorwaarden" tabindex="5" class="cc-link" href="{{tosHref}}" target="_blank">{{tosLink}}</a>
    </span>
    `
  },
  content:{
    message: 'Onze website maakt gebruik van functionele en niet-privacygevoelige cookies. Accepteert u daarnaast ook de plaatsing van andere soorten cookies?',
    
    cookiePolicyLink: 'Cookiebeleid',
    cookiePolicyHref: 'https://www.unit4.com/nl/over-unit4/beleidslijnen-en-gedragscode/cookiebeleid',

    privacyPolicyLink: 'Privacyverklaring',
    privacyPolicyHref: 'https://www.unit4.com/nl/over-unit4/beleidslijnen-en-gedragscode/privacy-verklaring',

    tosLink: 'Algemene voorwaarden',
    tosHref: 'https://www.unit4.com/nl/over-unit4/algemene-voorwaarden',
  }
};
*/

export const protectedResourceMap:[string, string[]][]=[
  ['https://graph.microsoft.com/v1.0/me', ['user.read']], 
  [environment.apiUrl, ['api://'+environment.apiApplicationId+'/access_as_user']]
];

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident") > -1 || window.navigator.userAgent.indexOf("Edge") > -1;

function MSALConfigFactory(): Configuration {
  return {
    auth: {
      clientId: environment.clientId,
      authority: "https://login.microsoftonline.com/common/",
      validateAuthority: true,
      redirectUri: environment.redirectUri,
      postLogoutRedirectUri: environment.postLogoutRedirectUri,
      navigateToLoginRequestUrl: true,      
    },
    cache: {
      cacheLocation: "sessionStorage",
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      loadFrameTimeout: 30000
    }  
  };
}

function MSALAngularConfigFactory(): MsalAngularConfiguration {
  return {
    popUp: !isIE,
    consentScopes: [
      "user.read",
      "openid",
      "profile",
      "email",
      "api://"+environment.apiApplicationId+"/access_as_user"
    ],
    unprotectedResources: ["https://www.microsoft.com/en-us/", environment.apiUrl + "/contact"],
    protectedResourceMap,
    extraQueryParameters: {}
  };
}

@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
//    NgcCookieConsentModule.forRoot(cookieConfig),
    FlexLayoutModule,
    WelcomeModule,
    MainModule,
    SharedModule,
    MsalModule,    
    MatInputModule,
    RouterModule.forRoot([
      { path: '**', 'redirectTo': '/welkom' }
  ])
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpOrganizationInterceptor,
      multi: true
    },   
    {
      provide: MSAL_CONFIG,
      useFactory: MSALConfigFactory
    },
    {
      provide: MSAL_CONFIG_ANGULAR,
      useFactory: MSALAngularConfigFactory
    },
    MsalService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
