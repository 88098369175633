<div *ngIf="isDataLoaded">
  <div>

    U wilt uw bestaande adviezen en klantgegevens gebruiken in DIAS Advies Cloud.
    <br> Dit kan door het maken van een export van uw database en deze hier te uploaden.
    <br> Het maken van een export van uw database is stap voor stap in deze
    <a href="http://files.dias.nl/downloads/Starten%20met%20DIAS%20Advies%20Cloud%20v2.0.pdf" target="_blank">handleiding</a> uitgelegd.


    <p>
      Na het maken van de export van uw database kunt u het bestand hieronder uploaden. Is uw database groter dan 2 GB dan is uploaden niet mogelijk. Neem contact op met de Servicedesk van DIAS Software om u verder te helpen.
      <br> Selecteer het exportbestand van uw database en start het uploaden.
      <br> Het uploaden van uw database kan afhankelijk van de grootte van de database en uw internetverbinding enige tijd duren.
    </p>
  </div>

  <div class="upload-box" *ngFor="let f of files; let i = index;" fxLayout="row" fxLayoutAlign="space-between none">

    <div *ngIf="!uploadSucces" class="upload-container">
      <div class="upload-progress">
        <mat-icon class="fa fa-database upload-progress-icon"></mat-icon>
      </div>
      <div fxFlex class="upload-section-info">
        <span class="filename">
          <span>{{ f.name }} - {{ f.progress?.data?.percentage }}%</span>
          <span>uploading with {{ f.progress?.data?.speedHuman }}</span>
          <span>ETA {{ f.progress?.data?.etaHuman }}</span>
        </span>
        <br>
        <mat-progress-bar class="upload-progress" [color]="color" [mode]="mode" [value]="f?.progress?.data?.percentage" [bufferValue]="bufferValue">
        </mat-progress-bar>

      </div>
      <button mat-raised-button class="btn-file-upload" color="primary" (click)="startUpload()" ng-disabled="isUploading">Uploaden</button>
      <button mat-raised-button class="btn-file-cancel" color="warn" (click)="removeFile(f.id)">Annuleren</button>
    </div>
  </div>

  <div *ngIf="uploadSucces" class="upload-container">
    <div fxFlex class="upload-section-info">
      <div style="text-align: center;">
        <h3>Het bestand is succesvol geüpload</h3>
        <br> Druk op de knop afronden om het process te starten.
      </div>
    </div>
  </div>

  <div *ngIf="!uploadSucces">
    <input id="fileToUpload" type="file" style="display:none;" ngFileSelect accept=".bacpac" (uploadOutput)="onUploadOutput($event)"
      [uploadInput]="uploadInput">
    <div class="drop-box" *ngIf="files.length===0">
      <div class="drop-container" fxFlexLayout="column">
        <div>
          <br>
          <label class="btn-upload" for="fileToUpload">Selecteer bestand</label>
          <br>
        </div>
      </div>
      <!-- <div  class="drop-container" ngFileDrop fxFlexLayout="column" [options]="options" (uploadOutput)="onUploadOutput($event)"
              [uploadInput]="uploadInput" [ngClass]="{ 'is-drop-over': dragOver }">
              <div>
                <br>
                <label class="btn-upload" for="fileToUpload">Selecteer bestand</label>
                <br>
              </div>
            </div> -->

    </div>
  </div>
  <br>
</div>