<div *ngIf="environmentStatus < 4">
We gaan onderstaande stappen uitvoeren om uw omgeving gereed te maken.<br> 
Na de laatste stap ontvangt u een e-mail om de software te downloaden.
</div>
<div  class="container">


  <div class="row bs-wizard" style="border-bottom:0;" fxLayout="row">

      <div fxFlex="25%" [ngClass]="{'complete':environmentStatus > 1,'active':environmentStatus == 1,'disabled':environmentStatus < 1}"
          class="bs-wizard-step complete">
          <div class="text-center bs-wizard-stepnum">Stap 1</div>
          <div class="progress">
              <div class="progress-bar"></div>
          </div>
          <a href="#" [ngClass]="{'blink_me':environmentStatus == 1}" class="bs-wizard-dot "></a>
          <div class="bs-wizard-info text-center">Uw nieuwe omgeving wordt aangemaakt.</div>
      </div>

      <div fxFlex="25%" [ngClass]="{'active':environmentStatus == 2,'complete':environmentStatus > 2 ,'disabled':environmentStatus < 2}"
          class=" bs-wizard-step ">
          <!-- complete -->
          <div class="text-center bs-wizard-stepnum">Stap 2</div>
          <div class="progress">
              <div class="progress-bar"></div>
          </div>
          <a href="#" [ngClass]="{'blink_me':environmentStatus == 2}" class="bs-wizard-dot"></a>
          <div class="bs-wizard-info text-center">Uw nieuwe omgeving is aangemaakt, maar moet nog worden ingericht.</div>
      </div>

      <div fxFlex="25%" [ngClass]="{'active':environmentStatus == 3,'complete':environmentStatus >3 ,'disabled':environmentStatus < 3}"
          class=" bs-wizard-step ">
          <!-- complete -->
          <div class="text-center bs-wizard-stepnum">Stap 3</div>
          <div class="progress">
              <div class="progress-bar"></div>
          </div>
          <a href="#" [ngClass]="{'blink_me':environmentStatus == 3}" class="bs-wizard-dot"></a>
          <div class="bs-wizard-info text-center">Uw nieuwe omgeving wordt ingericht.</div>
      </div>

      <div fxFlex="25%" [ngClass]="{'complete':environmentStatus == 4,'disabled':environmentStatus < 4}" class="bs-wizard-step  disabled">
          <!-- active -->
          <div class="text-center bs-wizard-stepnum">Stap 4</div>
          <div class="progress">
              <div class="progress-bar"></div>
          </div>
          <a href="#" class="bs-wizard-dot"></a>
          <div class="bs-wizard-info text-center"> Uw nieuwe omgeving is gereed.</div>
      </div>
  </div>

  <div class="row" style="border-bottom:0;" fxLayout="row">

    <div fxFlex="100%" [ngStyle]="{'visibility':environmentStatus == 1 ? 'visible' : 'hidden'}">
        <div>We maken uw omgeving aan. Dit kan een aantal minuten duren.</div>
    </div>
  </div>
</div>