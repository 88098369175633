<div *ngIf="isDataLoaded" fxLayout="row">
    <div fxFlex="70%">
        <div class="container">
            <div fxLayout="column">
                <mat-card class="wide-card">
                    <div class="box-header with-border">
                        <div>
                            <h3 class="box-title">Beste {{ name.trim()}}, </h3>
                        </div>
                    </div>
                    <div class="box-body">Welkom bij DIAS Advies Cloud.</div>

                    <div id="user" *ngIf="!isOwner" class="box-body">

                        Uw registratie als gebruiker van DIAS Advies Cloud is succesvol afgerond.
                        <!-- <p>
                            U ontvangt een e-mail met verder informatie om te starten met DIAS Advies Cloud.
                        </p> -->
                        <p>
                            Wilt u een extra gebruiker toegang geven tot DIAS Advies Cloud?
                            <br> Vraag uw beheerder van DIAS Advies Cloud om een extra gebruiker uit te nodigen.
                            <br>Uw beheerder doet dit vanuit DIAS Advies Cloud in het menu
                            <span class="notice_me">Systeem > Autorisatie</span>.

                        </p>
                        <p>
                            Wilt u uw lokale DIAS Advies database gebruiken in DIAS Advies Cloud?
                            <br> Vraag uw beheerder om in te loggen op
                            <a href="https://mijn.dias.nl" target="_blank">mijn.dias.nl</a> en een export van de lokale database te uploaden naar DIAS Advies Cloud.
                        </p>
                    </div>
                    <div id="start" *ngIf="isOwner && environmentStatus ===  0" class="box-body">


                        U kunt in DIAS Advies Cloud gebruik maken van uw adviezen en klantgegevens die u in uw lokale DIAS Advies applicatie
                        gemaakt heeft.
                        <br> U maakt hiervoor een export van uw lokale database.

                        <p>
                            <app-dbcheck [(dbcheck)]="initialdbcheck" [isDisabled]="isUploading || isUploaded"></app-dbcheck>
                        </p>

                        <span class="notice_me">Let op:</span> als u uw lokale DIAS Advies database niet uploadt naar DIAS Advies Cloud zal
                        een nieuwe database voor de cloud-versie worden aangemaakt.
                        <br> Als u op een later moment uw lokale DIAS Advies database alsnog wilt uploaden naar de cloud-versie
                        dan zullen alle relatiegegevens en adviezen, gemaakt in DIAS Advies Cloud, overschreven worden.

                    </div>
                    <div id="upload" *ngIf="isOwner && environmentStatus >  0 &&  environmentStatus < 4 " class="box-body">
                        <app-envprocess [status]="initialstatus"></app-envprocess>
                    </div>

                    <div id="finish" *ngIf="isOwner && environmentStatus >= 4" class="box-body">
                        Uw registratie als gebruiker van DIAS Advies Cloud is succesvol afgerond.
                        <app-envprocess [status]="initialstatus"></app-envprocess>
                        <p>
                            Wilt u een extra gebruiker toegang geven tot DIAS Advies Cloud?
                            <br> U kunt, door in te loggen op DIAS Advies Cloud, zelf een gebruiker uitnodigen.
                            <br> Kies in het menu
                            <span class="notice_me">Systeem > Autorisatie</span> voor de optie Gebruikers.
                            <br> Voer de gegevens en het e-mailadres in van de gebruiker die u wilt uitnodigen voor DIAS
                            Advies Cloud.
                            <br> Kies in het menu voor Opslaan en sluiten.
                            <br> De gebruiker wordt toegevoegd en ontvangt een e-mail.
                            <br> In de e-mail wordt een link vermeld waarmee de gebruiker zijn aanmelding moet bevestigen. Daarna
                            ontvangt hij een 2de e-mail.
                            <br> Deze bevat een link waarmee de client-software kan worden gedownload.
                            <br>Daarna kan de gebruiker direct aan de slag.
                        </p>
                        <div *ngIf="environmentStatus === 4">
                            Wilt u uw huidige database van DIAS Advies Cloud resetten of wilt u uw lokale DIAS Advies database gebruiken in DIAS
                            Advies Cloud? Klik dan op Reset Database
                            <br>
                            <p>
                                <button (click)="onResetDatabase()" mat-raised-button color="primary">Reset database</button>

                            </p>
                        </div>
                        <div *ngIf="environmentStatus === 0">
                            ss
                            <app-dbcheck [(dbcheck)]="initialdbcheck" [isDisabled]="isUploading || isUploaded" [defaultcheck]="false"></app-dbcheck>
                        </div>
                    </div>
                    <div id="footer" *ngIf="isOwner && environmentStatus === 0" class="box-body">
                        <p *ngIf="initialdbcheck === false ">
                            Klik op Afronden om uw aanmelding voor DIAS Advies Cloud te voltooien.
                            <br> Wij activeren dan uw licentie en maken uw omgeving gereed om direct aan de slag te kunnen.
                        </p>
                        <div *ngIf="initialdbcheck ">
                            <app-upload [uploadSucces]="uploaded" (uploadStarted)="handleUploadStarted($event)" (uploadChange)="handleUploaded($event)"></app-upload>
                        </div>

                        <div *ngIf=" initialdbcheck===false || isUploaded">
                            <button (click)="startProcess()" mat-raised-button color="primary">Afronden</button>
                        </div>
                    </div>
                </mat-card>
            </div>
        </div>
    </div>

</div>