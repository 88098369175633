
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from '../../../environments/environment';
import { Router, CanActivate } from "@angular/router";
// import { CommunicationService } from "../_services/index";
import { HttpClient, HttpHeaders } from '@angular/common/http'
import "rxjs/add/operator/map"
import 'rxjs/add/operator/toPromise';

import { AuthorizedUserModel } from "../../classes/index";
import { promise } from "protractor";
import { timeout } from "q";
import { MsalService } from '@azure/msal-angular';
import { Account } from 'msal';
import { Organization } from '../../classes/organization.class';

@Injectable({
    providedIn: 'root',
  })

export class AuthorisationService {
    private baseUrl = environment.apiUrl;
    public isSelfSignUp = false;

    // get httpOptions() {
    //     return { 'headers': new HttpHeaders({ }), withCredentials : true };
    // };

    get httpOptions() {
        return { "headers": new HttpHeaders({ 'Content-Type': 'application/json', 'Accept': 'application/json'}),
            withCredentials : true
        };
      };
  
    
    constructor(private httpClient: HttpClient, private authService : MsalService, private router: Router) {             
    }

    public getCurrentUser(): Promise<Account> {
        var account = this.authService.getAccount();
        if (account != null) {
          return new Promise(() => account);
        }
    }

    get name() {
        var account = this.authService.getAccount();
        if (account != null) {
            return account.name;
        }
    }    

    public async isOrganizationAdmin() {
        const url = `${this.baseUrl + '/isOrganizationAdmin'}`;
        return await this.httpClient
            .get<boolean>(url, this.httpOptions)
            .toPromise<boolean>();
    }

    public async getOrganizations() : Promise<any[]> {
        const url = `${this.baseUrl + '/organizations'}`;
        return await this.httpClient
            .get<any[]>(url, this.httpOptions)
            .toPromise<any[]>();
    }
}
