
import { Injectable } from '@angular/core';
import { RouterModule, Routes, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from "@angular/router";
import { Observable } from 'rxjs';
import { MsalGuard, MsalService } from '@azure/msal-angular';
import { InteractionRequiredAuthError, AuthError } from 'msal';
import { Organization } from '../../classes/organization.class';
import { OauthGuard } from '../../guards/oauth.guard';

@Injectable()
export class StartGuard implements CanActivate {
    constructor(private oauthGuard: OauthGuard, private organization: Organization, private router: Router, private route: ActivatedRoute) {
    }

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

        var canActivate = this.oauthGuard.canActivate(next, state);

        if (!canActivate) {
            return false;
        }        

        let currentOrganization = await this.organization.hasOrganization();
        if (!currentOrganization) {
            this.router.navigate([`/advies/trialenvironment`]);
            return false;
        }

        return true;
    }
}