<div fxFlexFill fxLayout="column" class="container-component">
  <app-message #modalMessage [message]="modalMessage"></app-message>
  <app-loader #modalLoader [loader]="modalLoader"></app-loader>
  <mat-toolbar [style.display]="(loggedIn) ? 'none' : 'flex' "> 
    <div class="toolbar-spacer"></div>

    <!--   
    <a mat-button [routerLink]="['profile']">Profile</a>
-->  
    <img alt="DIAS Software B.v." height="49" width="149" data-src="https://dias.nl/wp-content/uploads/2020/03/logo.svg" class="custom-logo lazyloaded" src="https://dias.nl/wp-content/uploads/2020/03/logo.svg" loading="lazy">

    <span class="fill-remaining-space"></span>

    <button mat-button color="primary" *ngIf="!loggedIn" (click)="login()">Login</button>

  </mat-toolbar>

  <div fxWidth="100%">
    <router-outlet></router-outlet>
  </div>
</div>
